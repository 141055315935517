import { ErrorMessage } from "@/@core/utils/wordings"
import Vue from "vue"

export default {
  namespaced: true,
  state: {
    changeLogs: [],
  },
  mutations: {
    SET_CHANGE_LOGS(state, { data }) {
      state.changeLogs = data
    },
  },
  actions: {
    async getChangeLogs({ commit}) {
      try {
        const response = await Vue.axios.get('change-logs/get.json')

        return response.data.data
      } catch (error) {
        const message = error.response?.data?.message || ErrorMessage
        Vue.prototype.$custom.processCommError({ message }, 'get change logs', true)
      }
    },
  },
}
